
import Vue from "vue";

export default Vue.extend({
  name: "RequestRefundModal",
  data() {
    return {
      text: "",
      maxTextChars: 500,
      btnLoading: false
    };
  },
  methods: {
    requestFund() {
      this.btnLoading = true;

      this.$store
        .dispatch("program/REFUND_PROGRAM", {
          id: this.$route.params.id,
          reason: this.text
        })
        .then(() => {
          this.btnLoading = false;
          (this as any).$alertify.success(
            `Program refund request submitted successfully`
          );
          this.$bvModal.hide("request-refund");
          this.$emit("refresh");
        })
        .catch(() => {
          this.btnLoading = false;
        });
    }
  }
});
